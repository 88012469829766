<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="customer_calls"
                          tableFormat="App\Managers\Format\Reports\CustomerCalls"
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          title="common.menu.reports.customer_calls"
                          :actions-list="getActions()"
                >
                    <template v-slot:top-actions>
                        <b-form inline>
                            <font-awesome-icon
                                role="button"
                                icon="arrow-left"
                                @click="prevMonth()"
                            />&nbsp;
                            <b-form-select
                                v-model="month"
                                :options="monthsList()"
                                class="mr-2"
                                @change="monthChanged"
                            ></b-form-select>
                            <font-awesome-icon
                                role="button"
                                icon="arrow-right"
                                @click="nextMonth()"
                            />
                        </b-form>
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'
import tableDateFilter from '@/bundles/erika_common_bundle/mixins/table-date-filter'

export default {
    name: 'CustomerCalls',
    mixins: [commonTable, tableDateFilter],
    data() {
        return {
            saveFilters: true,
            dateField: 'date'
        }
    },
    methods: {
        ...mapGetters('CommonTable', ['getCommonTable']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'CommonTable/fetchTableData', 'getCommonTable');
        },
        ctxBaseParams() {
            return {
                'url': '/reports/customer_calls',
                'format': 'App\\Managers\\Format\\Reports\\CustomerCalls'
            }
        },
        getActions() {
            return []
        },
    },
}
</script>